@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
body {
  background-color: #e9ebee;
}

.buttonLogin {
  width: 100%;
  font-size: 18px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.configuration {
  min-height: 700px;
}

.home {
  position: absolute;
  background-color: rgba(1, 12, 15, 0.95);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.error {
  color: red;
  text-align: center;
  margin-top: 14px;
  font-size: 1.2em;
}

.btn-circle {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  margin-right: 30px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
  margin-right: 30px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
  margin-right: 30px;
}

.navbar-inverse {
  background-image: linear-gradient(to bottom, #a0bca2 0, #330000);
}

.ui.buttons .or:before {
  position: absolute;
  text-align: center;
  border-radius: 500rem;
  content: "or";
  top: 50%;
  left: -200%;
  background-color: #fff;
  text-shadow: none;
  margin-top: -0.89285714em;
  margin-left: -0.89285714em;
  width: 3em;
  height: 1.785714em;
  line-height: 1.785714em;
  color: rgba(0, 0, 0, 0.4);
  font-style: normal;
  font-weight: 900;
  -webkit-box-shadow: 0 0 0 1px transparent inset;
  box-shadow: 0 0 0 1px transparent inset;
}

.ui.centered.grid,
.ui.centered.grid > .row,
.ui.grid > .centered.row {
  text-align: left;
  -ms-flex-pack: center;
  justify-content: center;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  background-color: white;
}

.lineChart {
  /* background-image: url('/assets/consumo2.png'); 
   opacity: 0.9; 
   background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;  */

  color: white;
  font-size: 1.05em;
  text-shadow: 3px 3px black;
  font-weight: bold;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.custom-tooltip {
  width: 200px;
  background-color: white;
  opacity: 0.75;
  font-weight: bold;
  color: black;
  text-shadow: none;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
}

.texto-etiquetas {
  font-weight: bold;
}

.content-box-ith {
  background-color: #fa0000;
  border: 1px solid #500101;
}

.content-box-ms {
  background-color: #12f307;
  border: 1px solid #02411f;
}

.content-box-mc {
  background-color: #fefe08;
  border: 1px solid #b6b00f;
}

.ui.menu .item > img:not(.ui) {
  display: inline-block;
  vertical-align: middle;
  width: 120px;
}
.logo {
  border-style: none;
  width: 120px;
  /* height: 120px; */
}

.ui[class*="center aligned"].segment {
  margin-top: 0;
  margin-bottom: 0;
}

.ui[class*="left aligned"].segment {
  margin-top: 0;
  margin-bottom: 0;
}

/*************
******/

.body {
  font-family: system;
  margin: 0;
  padding: 0;
}

.container-init {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: [inicio] 1fr [linea2] 1fr [linea3] 1fr [linea4] 1fr [destacado-end] 450px [final];
  grid-template-rows: [inicio] 1fr [linea-2] 1fr [inicio-login] 100px [inicio-contacto] 1fr [final];
  height: 100vh;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/control-ganadero-ee861.appspot.com/o/static%2Ffcamp.jpg?alt=media&token=40679c2e-1729-43df-ac27-8c194d50fc46");
  background-repeat: none;
  background-size: cover;
}

/* .item { */
/* background: lightblue; */
/* padding: 10px; */
/* border: 1px solid red; */
/* } */
.item-init:nth-of-type(1) {
  grid-column: destacado-end / final;
}

.item-init:nth-of-type(2) {
  grid-column: linea2 / linea4;
  grid-row: inicio / inicio-login;
  margin-bottom: 10px;
}
.item-init:nth-of-type(3) {
  grid-column: inicio / destacado-end;
  grid-row: inicio-login / final;
}
.item-init {
  margin-left: 5%;
  height: auto;
}

.container-login {
  background: #1d68d8;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  /* grid-gap: 5px; */
  height: 100vh;
  margin: 0;
  padding: 0;
  justify-items: center;
  align-items: flex-start;
}

.item-login {
  /* background: red; */
  padding: 10px;
  /* border: 1px solid red; */
}

.item-login p {
  font-family: "Permanent Marker", cursive;
  color: white;
  font-size: 52px;
  margin: 5px;
  text-align: center;
}

.item-login:nth-of-type(1) img {
  width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.item-login:nth-of-type(2) form {
  color: white;
  text-align: center;
  font-size: 1.3em;
  font-weight: bold;
}

form {
  border: 3px solid #f1f1f1;
}

#slide {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/control-ganadero-ee861.appspot.com/o/static%2F1.png?alt=media&token=d49ab107-366f-4b06-bf75-4048368d5e55");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Full-width inputs */
input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 1em;
}

/* Set a style for all buttons */
.buttonLogin {
  background-color: #4caf50;
  color: white;
  font-size: 1em;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

/* Add a hover effect for buttons */
.buttonLogin:hover {
  opacity: 0.8;
}

@media (max-width: 680px) {
  .item-init:nth-of-type(2) {
    display: none;
  }
  .item-init:nth-of-type(3) {
    display: none;
  }
  .item-init:nth-of-type(4) {
    display: none;
  }

  .item-init:nth-of-type(1) {
    /* grid-column: linea2 / final; */
    grid-row: inicio / final;
    grid-column: inicio / final;
  }
}

@media (max-width: 1140px) {
  .item-init video {
    margin-left: 7%;
    width: 76%;
  }
}

@media (max-width: 1100px) {
  .item-init video {
    margin-left: 10%;
    width: 80%;
  }
}

@media (max-width: 1040px) {
  .item-init video {
    margin-left: 5%;
    width: 90%;
  }
}

@media (max-width: 980px) {
  .item-init video {
    margin-left: 0;
    width: 100%;
  }
}

.errorLogin {
  font-size: 0.8em;
  color: white;
}

.shadow {
  -webkit-box-shadow: -1px 17px 84px 4px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: -1px 17px 84px 4px rgba(0, 0, 0, 0.67);
  box-shadow: -1px 17px 84px 4px rgba(0, 0, 0, 0.67);
  margin: 30px;
}

.container-clock {
  /* height: 30px; */
  margin-top: 16px;
  margin-bottom: 4px;
  margin-left: 1px;
  text-align: center;
}

.title-clock {
  font-family: "Ultra", serif;
  text-shadow: 2px 2px rgb(245, 186, 186);
  font-size: 22px;
  /* margin-left: 10px; */
  text-align: center;
}

.clock {
  font-family: "Ultra", serif;
  text-shadow: 1px 2px #ff0000;
  font-size: 28px;
  border: 2px solid grey;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  margin: 4px;
  background-color: black;
  color: rgb(202, 192, 192);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.caja-clock {
  margin-top: 8px;
  margin-left: 10px;
  padding: 10px;
  background-color: rgba(231, 228, 228, 0.507);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.caja-clock-fechas {
  /* margin-left: 160px; */
  margin-top: 4px;
  padding: 6px;
  text-align: center;
}

.span-rango-fechas {
  margin-top: 9px;
  text-shadow: 2px 2px white;
  color: black;
  font-size: 20px;
  margin-left: 10px;
  padding: 8px;
  text-align: center;
}

.contenedorGlobo {
  margin-left: 20px;
  padding: 5px;
}

.circuloBlanco {
  /* background-color: white; */
  margin-top: 20px;
  font-size: 1.3em;
  text-align: center;
  border-radius: 50%;
  padding: 18px;
  font-weight: bold;
}
.datoPresicion {
  margin-bottom: 6px;
}

.datoPresicionValor {
  margin-top: 3px;
}
